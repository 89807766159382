import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";
import TopTen from "../views/TopTen.vue";
import MovieData from "../views/MovieData";
import AllTicket from "../views/AllTicket";
import YearTop from "../views/YearTop";
import ScdTop from "../views/ScdTop";
import Login from "../views/user/Login";
import AllRanking from "../views/AllRanking";
import Recommend from "../views/Recommend";
import nowTimes from "../views/nowTimes";

Vue.use(VueRouter);

const originalPush = VueRouter.prototype.push

VueRouter.prototype.push = function push(location, onResolve, onReject) {
    if (onResolve || onReject) return originalPush.call(this, location, onResolve, onReject)
    return originalPush.call(this, location).catch(err => err)
}

const routes = [{
		path: "/",
		name: "Home",
		component: Home,
		meta: {
			title: "首页",
			reqAuth: true
		},
		children: [{
				path: "/top_ten",
				name: "top_ten",
				component: TopTen,
				meta: {
					title: "top 10",
					reqAuth: true
				}
			},
			{
				path: "/movie_data",
				name: "movie_data",
				component: MovieData,
				meta: {
					title: "数据概况",
					reqAuth: true
				}
			}, {
				path: "/all_ticket",
				name: "all_ticket",
				component: AllTicket,
				meta: {
					title: "年总票房趋势",
					reqAuth: true
				}
			}, {
				path: "/year_top",
				name: "year_top",
				component: YearTop,
				meta: {
					title: "年度top5",
					reqAuth: true
				}
			},
			{
				path: "/all_ranking",
				name: "all_ranking",
				component: AllRanking,
				meta: {
					title: "总网票排行",
					reqAuth: true
				}
			},
			{
				path: "/now_times",
				name: "now_times",
				component: nowTimes,
				meta: {
					title: "实时票房",
					reqAuth: true
				}
			},{
				path: "/recommend",
				name: "recommend",
				component: Recommend,
				meta: {
					title: "电影推荐",
					reqAuth: true
				}
			}, {
				path: "/scd_top",
				name: "scd_top",
				component: ScdTop,
				meta: {
					title: "scd top 25",
					reqAuth: true
				}
			}
		]
	},
	{
		path: "/login",
		name: "login",
		component: Login,
		meta: {
			title: "登录注册"
		}
	}
];


const router = new VueRouter({
	routes,
});

router.beforeEach((to, from, next) => {
	


	if (to.meta.reqAuth) {
		if (!localStorage.getItem("token")) {
			router.push({
				path: "/login",
				query: {
					want: to.path
				}
			})
		} 
		else {
			let timeNow = parseInt(((new Date()).getTime()).toString().substr(0, 10));
			let token_expired = parseInt(localStorage.getItem("token_expired"));
			console.log(timeNow)
			console.log(token_expired)
			if (timeNow >= token_expired) {
				router.push({
					path: "/login",
					query: {
						want: to.path
					}
				})
			}
		}
	}

	if (to.meta.title) {
		document.title = to.meta.title;
		if(to.path=="/login"){
			console.log("no")
			next()
		}else if(to.path === "/") {
			if (!localStorage.getItem("list")) {
				console.log("no")
			} else {
				let lists = JSON.parse(localStorage.getItem("list"));
				lists.forEach((item, index) => {
					lists[index].type = "info";
				});
				console.log(lists)
				localStorage.setItem("list", JSON.stringify(lists));
				let cus = new CustomEvent("tags_dt");
				window.dispatchEvent(cus);
			}
			next();
		} else {
			if (!localStorage.getItem("list")) {
				localStorage.setItem("list", JSON.stringify([{
					name: to.meta.title,
					path: to.path
				}]))
			} else {
				let lists = JSON.parse(localStorage.getItem("list"));
				let isH = false;
				lists.forEach((item) => {
					if (item.name === to.meta.title) {
						isH = true;
					}
				})
				if (!isH) {
					lists.push({
						name: to.meta.title,
						path: to.path
					});
				}
				lists.forEach((item, index) => {
					if (item.path === to.path) {
						lists[index].type = "";
					} else {
						lists[index].type = "info";
					}
				});
				localStorage.setItem("list", JSON.stringify(lists));
			}
			let cus = new CustomEvent("tags_dt");
			window.dispatchEvent(cus);
			next();
		}
		
	}
	next()
})


export default router;
