<template>
	<el-container>
		<el-header>
			<el-breadcrumb separator-class="el-icon-arrow-right">
				<el-breadcrumb-item :to="{ path: '/' }">数据可视化</el-breadcrumb-item>
				<el-breadcrumb-item>年总票房趋势</el-breadcrumb-item>
			</el-breadcrumb>
		</el-header>
		<el-main>
			<div id="main" style="width: 800px; height:600px;margin: 0 auto"></div>
		</el-main>
	</el-container>
</template>

<script>
	import axios from "axios";
	import * as echarts from 'echarts';
	export default {
		name: "AllTicket",
		data() {
			return {
				top_tens_name: [],
				top_ten_data: [],
				select_top_ten: [],
			}
		},
		mounted() {
			this.getData()
		},
		methods: {
			async getData() {
				let res = await axios.get("/api/all_ticket")
				let datas = res.data;
				let top_names = [];
				let top_datas = [];
				datas.forEach(item => {
					top_names.push(item.year + "年")
					top_datas.push(item.box_office)
				});
				this.top_ten_name = top_names;
				this.top_ten_data = top_datas;
				console.log(this.top_ten_name)
				console.log(this.top_ten_data)
				this.setCharts()
			},
			setCharts() {
				let main = echarts.init(document.getElementById("main"));
				//配置echarts
				var option = {
					title: {
						text: '年度票房变化',
						subtext: '单位（w）'
					},
					tooltip: {
						trigger: 'axis'
					},
					legend: {
						data: ['票房数据(万)']
					},
					toolbox: {
						show: true,
						feature: {
							dataZoom: {
								yAxisIndex: 'none'
							},
							dataView: {
								readOnly: false
							},
							magicType: {
								type: ['line', 'bar']
							},
							restore: {},
							saveAsImage: {}
						}
					},
					xAxis: {
						type: 'category',
						boundaryGap: false,
						data: this.top_ten_name
					},
					yAxis: {
						type: 'value',
						axisLabel: {
							formatter: '{value} 万'
						}
					},
					series: [{
						name: '票房数据(万)',
						type: 'line',
						data: this.top_ten_data,
						markPoint: {
							data: [{
									type: 'max',
									name: '最大值'
								},
								{
									type: 'min',
									name: '最小值'
								}
							]
						},
						markLine: {
							data: [{
								type: 'average',
								name: '平均值'
							}]
						}
					}]
				};

				//把配置单给echarts
				main.setOption(option)
			},
		},
	};
</script>

<style scoped></style>
