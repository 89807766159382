<template>
	<div>
		
		<div style="position: fixed;z-index: 5;background-color: #00193A;">
		
		</div>
		
		<div style="width: 1200px;height: 500px;background-color: #00193A;">
			<div style="margin: 0 auto;color: white;">当前时间{{nowTime}}</div>
			<div id="main_left" style="width: 400px; height:400px;float: left;"></div>
			<div style="width: 330px;height:400px;float:left;background-color: white;">
				<el-table :data="tables_data_now" height="400" id="movie">
					<el-table-column sortable prop="movie" label="电影" width="80">
					</el-table-column>
					<el-table-column sortable prop="boxOffice" label="总票房" width="80">
					</el-table-column>
					<el-table-column sortable prop="boxRate" label="票房占比" width="80">
					</el-table-column>
					<el-table-column sortable prop="showCount" label="排片场次" width="80">
					</el-table-column>
				</el-table>
			</div>
			
			<div v-loading="isLoading" id="main_right" style="width: 400px; height:400px;margin-right: 30px;float: right;">
			</div>
		</div>
	</div>
</template>

<script>
	import axios from 'axios'
	import * as echarts from 'echarts';
	export default {
		data() {
			return {
				tables_data_now: [], //实时票房
				tables_data_left: {name:[],data:[]}, //左侧数据
				tablse_data_right: {}, //右侧数据
				isLoading: false,
				nowTime:"",
				nowTimeShow:"开始"
			}
		},
		mounted() {
			this.get_now_data()
			setInterval(()=>{
				this.timeDel()
			},1000)
			setInterval(()=>{
				this.get_now_data()
			},5000)
		},
		methods: {
			timeDel() {
				var date = new Date(); //时间戳为10位需*1000，时间戳为13位的话不需乘1000
				var Y = date.getFullYear() + '-';
				var M = (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1) + '-';
				var D = (date.getDate() < 10 ? '0' + date.getDate() : date.getDate()) + ' ';
				var h = (date.getHours() < 10 ? '0' + date.getHours() : date.getHours()) + ':';
				var m = (date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes()) + ':';
				var s = date.getSeconds() < 10 ? '0' + date.getSeconds() : date.getSeconds();
				this.nowTimeShow = h + m + s;
				this.nowTime = Y + M + D + h + m + s;
			},
			get_now_data() {
				axios.get("/apk").then(res => {
					let datas = res.data.movieList.data.list;
					let now_all_box = res.data.movieList.data.nationBoxInfo.nationBoxSplitUnit.num;
					now_all_box = parseFloat(now_all_box)
					let left_data = this.tables_data_left;
					if(left_data.data.length>=20){
						left_data.data.splice(0,1)
						left_data.name.splice(0,1)
					}
					left_data.data = left_data.data.concat(now_all_box)
					left_data.name = left_data.name.concat(this.nowTimeShow)
					this.tables_data_left = left_data
					let now_data = [];
					let right_data = [];
					let right_name = [];
					datas.forEach((item, index) => {
						if (index < 5) {
							right_name.push(item.movieInfo.movieName)
							right_data.push(parseFloat(item.boxRate.substr(0, item.boxRate.length - 1)))
						}
						now_data.push({
							boxRate: item.boxRate,
							movie: item.movieInfo.movieName,
							showCount: item.showCount,
							boxOffice: item.sumSplitBoxDesc
						})
					})
					this.tables_data_now = now_data;
					this.tablse_data_right = {
						name: right_name,
						data: right_data
					}
					this.setChartsRight()
					this.setChartsLeft()
				})
			},
			setChartsRight() {
				let main = echarts.init(document.getElementById("main_right"));
				//配置echarts
				let option = {
					title: {
						text: '票房占比',
						subtext: 'From MaoYan',
						sublink: 'http://piaofang.maoyan.com/dashboard',
						textStyle: { //数值样式
							color: 'white'
						},
					},
					xAxis: {
						type: "category",
						data: this.tablse_data_right.name,
						axisLabel: {
							interval: 0, //横轴信息全部显示
							rotate: -20, //-30度角倾斜显示
							color: "white"
						}
					},
					yAxis: {
						type: "value",
						axisLabel: {
							color: "white"
						}
					},
					series: [{
						data: this.tablse_data_right.data,
						type: "bar",
						showBackground: true,
						backgroundStyle: {
							color: "rgba(180, 180, 180, 0.2)",
						},
						itemStyle: {
							normal: {
								label: {
									show: true, //开启显示
									position: 'top', //在上方显示
									textStyle: { //数值样式
										color: 'white',
										fontSize: 16
									},
									formatter: '{c}%'
								}
							}
						}
					}, ],
				};

				main.setOption(option,true);
				this.isLoading = false;
			},
			setChartsLeft() {
				let main = echarts.init(document.getElementById("main_left"));
				//配置echarts
				var option = {
					title: {
						text: '当日实时票房变化',
						subtext: '单位（w）',
						textStyle: { //数值样式
							color: 'white'
						},
					},
					tooltip: {
						trigger: 'axis',
						
					},
					legend: {
						data: ['票房数据(万)']
					},
					toolbox: {
						show: true,
						feature: {
							dataZoom: {
								yAxisIndex: 'none'
							},
							dataView: {
								readOnly: false
							},
							magicType: {
								type: ['line', 'bar']
							},
							restore: {},
							saveAsImage: {}
						}
					},
					xAxis: {
						type: 'category',
						boundaryGap: false,
						data: this.tables_data_left.name,
						axisLabel: {
							color: "white"
						}
					},
					yAxis: {
						type: 'value',
						axisLabel: {
							formatter: '{value} 万',
							color: "white",
							fontSize: 7
						}
					},
					series: [{
						name: '票房数据(万)',
						type: 'line',
						data: this.tables_data_left.data,
						markPoint: {
							data: [{
									type: 'max',
									name: '最大值'
								},
								{
									type: 'min',
									name: '最小值'
								}
							]
						},
						markLine: {
							data: [{
								type: 'average',
								name: '平均值'
							}]
						}
					}]
				};
			
				//把配置单给echarts
				main.setOption(option,true)
			}
		}
	}
</script>

<style>

</style>
