<template>
	<div>
		<div>
			<el-input placeholder="电影名称" v-model="movie" @keyup.enter.native="getData(123)" style="width: 200px;">
			</el-input>
			<el-button @click="getData(123)" type="primary" style="margin-left: 10px;">检索</el-button>
			<div style="margin-top: 20px;"></div>
			类型：<el-button v-for="(item,index) in types" :key="index" size="mini" @click="selectT(item.name)"
				:type="item.type">{{item.name}}</el-button>
				<div style="margin-top: 20px;"></div>
			年份：<el-button v-for="(item,index) in years" :key="index" size="mini" @click="selectY(item)"
				:type="item.type">{{item.name}}</el-button>

		</div>
		<div v-loading="isLoading">
			<el-table :data="tableData" style="width: 100%">
				<el-table-column sortable prop="rank" label="排名">
				</el-table-column>
				<el-table-column sortable prop="movie" label="电影">
				</el-table-column>
				<el-table-column sortable prop="comments" label="热度">
				</el-table-column>
				<el-table-column sortable prop="type" label="类型">
				</el-table-column>
				<el-table-column sortable prop="year" label="年份">
				</el-table-column>
				<el-table-column sortable prop="rating_num" label="评级">
				</el-table-column>
				<el-table-column sortable prop="rating_star" label="星级">
				</el-table-column>
			</el-table>
		</div>
		<el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="page"
			:page-sizes="[10, 20, 30, 40]" :page-size="pageSize" layout="total, sizes, prev, pager, next, jumper"
			:total="totals">
		</el-pagination>
	</div>
</template>

<script>
	import axios from 'axios'
	export default {
		data() {
			return {
				types: [{
						name: "动画",
						type: ""
					},
					{
						name: "喜剧",
						type: ""
					},
					{
						name: "爱情",
						type: ""
					},
					{
						name: "悬疑",
						type: ""
					},
					{
						name: "科幻",
						type: ""
					},
					{
						name: "惊悚",
						type: ""
					},
					{
						name: "犯罪",
						type: ""
					},
					{
						name: "剧情",
						type: ""
					},
					{
						name: "动作",
						type: ""
					},
					{
						name: "奇幻",
						type: ""
					},
					{
						name: "战争",
						type: ""
					},
					{
						name: "纪录片",
						type: ""
					},
					{
						name: "家庭",
						type: ""
					},
					{
						name: "历史",
						type: ""
					}
				],
				years: [{
					name: "2015-2020",
					type: "",
					s: 2015,
					e: 2020
				}, {
					name: "2010-2015",
					type: "",
					s: 2010,
					e: 2015
				}, {
					name: "2005-2010",
					type: "",
					s: 2005,
					e: 2010
				}, {
					name: "2000-2005",
					type: "",
					s: 2000,
					e: 2005
				}, {
					name: "2000年以下",
					type: "",
					s: 1900,
					e: 2000
				}],
				year: {s:1900,e:2020},
				tableData: [],
				isLoading: false,
				type: "",
				page: 1,
				pageSize: 10,
				totals: 0,
				movie: ""
			}
		},
		mounted() {
			this.getData()
		},
		methods: {
			handleSizeChange(e) {
				this.pageSize = e;
				this.getData()
			},
			handleCurrentChange(e) {
				this.page = e;
				this.getData()
			},
			getData(ts) {
				if (ts === 123) {
					this.type = ""
					let types = this.types
					types.forEach((items, index) => {
						types[index].type = ""
					})
					this.types = types
					let years = this.years
					years.forEach((items, index) => {
						years[index].type = ""
					})
					this.years = years
				}
				this.isLoading = true
				axios.get(
					`/api/get_recommend?type=${this.type}&page=${this.page}&page_size=${this.pageSize}&movie=${this.movie}&s=${this.year.s}&e=${this.year.e}`
					).then(res => {
					this.tableData = res.data.data
					this.totals = res.data.total
					this.isLoading = false
				})
			},
			selectT(item) {
				this.movie = "";
				this.type = item;
				let types = this.types
				types.forEach((items, index) => {
					if (item == items.name) {
						types[index].type = "primary"
					} else {
						types[index].type = ""
					}
				})
				this.types = types
				this.getData()
			},
			selectY(item){
				this.movie = "";
				this.year = item;
				let years = this.years
				years.forEach((items, index) => {
					if (item.name == items.name) {
						years[index].type = "primary"
					} else {
						years[index].type = ""
					}
				})
				this.years = years
				this.getData()
			}
		}
	}
</script>

<style>
	.selects {
		background-color: blue;
		color: white;
	}
</style>
