<template>
	<el-container>
		<el-header>
			<el-breadcrumb separator-class="el-icon-arrow-right">
				<el-breadcrumb-item :to="{ path: '/' }">数据可视化</el-breadcrumb-item>
				<el-breadcrumb-item>Top 5</el-breadcrumb-item>
			</el-breadcrumb>
		</el-header>
		<el-main>
			年份：<el-select v-model="year" placeholder="请选择">
				<el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
				</el-option>
			</el-select>
			<div v-loading="isLoading" id="main" style="width: 800px; height:600px;margin: 0 auto"></div>
		</el-main>
	</el-container>
</template>

<script>
	import axios from "axios";
	import * as echarts from 'echarts';
	export default {
		name: "YearTop",
		data() {
			return {
				top_tens_name: [],
				top_ten_data: [],
				select_top_ten: [],
				isLoading: false,
				year: 2019,
				options: [{
					value: 2019,
					label: '2019'
				}, {
					value: 2018,
					label: '2018'
				}, {
					value: 2017,
					label: '2017'
				}, {
					value: 2016,
					label: '2016'
				}, {
					value: 2015,
					label: '2015'
				}, {
					value: 2014,
					label: '2014'
				}, {
					value: 2013,
					label: '2013'
				}, {
					value: 2012,
					label: '2012'
				}, {
					value: 2011,
					label: '2011'
				}, {
					value: 2010,
					label: '2010'
				}]
			}
		},
		mounted() {
			this.getData()
		},
		watch: {
			year() {
				this.getData()
			}
		},
		methods: {
			async getData() {
				this.isLoading = true;
				let res = await axios.get("/api/top_five?year=" + this.year)
				let datas = res.data;
				let top_names = [];
				let top_datas = [];
				datas.forEach(item => {
					top_names.push(item.movie)
					top_datas.push(item.box_office)
				});
				this.top_ten_name = top_names;
				this.top_ten_data = top_datas;
				this.setCharts()
			},
			setCharts() {
				let main = echarts.init(document.getElementById("main"));
				//配置echarts
				let option = {
					xAxis: {
						type: "category",
						data: this.top_ten_name,
						axisLabel: {
							interval: 0, //横轴信息全部显示
							rotate: -20, //-30度角倾斜显示
							color: "black"
						}
					},
					yAxis: {
						type: "value",
					},
					series: [{
						data: this.top_ten_data,
						type: "bar",
						showBackground: true,
						backgroundStyle: {
							color: "rgba(180, 180, 180, 0.2)",
						},
					}, ],
				};

				main.setOption(option);
				this.isLoading = false;
			},
		},
	};
</script>

<style scoped></style>
