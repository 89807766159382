<template>
	<div class="bgs">
		<div class="logins" v-loading="isLoadings">
			<img src="/logo_mdv.png" />
			<div>
				<h3>{{shows}}</h3>
				<div style="margin-top: 40px;"></div>
				<el-input style="width: 300px;" v-model="username" type="text" placeholder="用户名" @keyup.enter.native="loginOregister()" clearable></el-input>
				<div style="margin-top: 40px;"></div>
				<el-input style="width: 300px;" v-model="password" type="password" placeholder="密码" @keyup.enter.native="loginOregister()" clearable></el-input>
				<div style="margin-top: 20px;"></div>
				<el-button type="primary" @click="loginOregister()" :disabled="isLoading">{{shows}}</el-button> <el-button type="success" :disabled="isLoading" @click="sign_t=!sign_t">{{showss}}</el-button>
			</div>
		</div>
	</div>
</template>

<script>
	import axios from 'axios'
	export default{
		data(){
			return{
				username:"",
				password:"",
				sign_t:false,
				isLoadings:false
			}
		},
		computed:{
			shows(){
				if(this.sign_t){
					return "注册"
				}else{
					return "登录"
				}
			},
			showss(){
				if(this.sign_t){
					return "去登录"
				}else{
					return "去注册"
				}
			},
		},
		watch:{
			username(){
				if(this.sign_t){
					this.check_username();
				}
			},
			sign_t(){
				this.username = "";
				this.password = "";
			}
		},
		methods:{
			async loginOregister(){
				this.isLoadings = true;
				if(!this.sign_t){
					let res = await axios.post(`/api/login?username=${this.username}&password=${this.password}`)
					if(res.data.code==0){
						localStorage.setItem("token",res.data.token)
						localStorage.setItem("token_expired",res.data.token_expired)
						localStorage.setItem("role",res.data.role)
						this.isLoadings=false
						if(this.$route.query.want){
							this.$router.push(this.$route.query.want)
						}else{
							this.$router.push("/")
						}
					}else{
						this.isLoadings=false
						this.$message.error(res.data.msg)
					}
				}else{
					let res = await axios.post(`/api/register?username=${this.username}&password=${this.password}`);
					if(res.data.code==0){
						localStorage.setItem("token",res.data.token)
						localStorage.setItem("token_expired",res.data.token_expired)
						localStorage.setItem("role",res.data.role)
						this.isLoadings=false
						if(this.$route.query.want){
							this.$router.push(this.$route.query.want)
						}else{
							this.$router.push("/")
						}
					}else{
						this.isLoadings=false
						this.$message.error(res.data.msg)
					}
					
				}
				
			},
			check_username(){
				axios.post(`/api/check_name?username=${this.username}`).then(res=>{
					if(res.data.code===0){
						console.log("yes")
					}else{
						this.$message.error("用户名重复")
					}
				})
			}
		}
	}
</script>

<style>
	.logins {
		border: #d5d5d5 1px solid;
		width: 400px;
		height: 400px;
		position: absolute;
		left: 50%;
		top: 50%;
		margin-left: -200px;
		margin-top: -200px;
		box-shadow: 0 2px 4px rgba(0, 0, 0, .12), 0 0 6px rgba(0, 0, 0, .04);
		background-color: white;
	}
	.bgs{
		background: url("/11.jpeg");
		width: 100%;
		height: 96vh;
		background-size: cover;
	}
</style>
