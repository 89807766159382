<template>
	<el-container>
		<el-header style="border-bottom: 1px solid #e2e2e2">
			<img src="/logo_mdv.png" style="height: 50px; margin-top: 5px; float: left" />

			<div style="float: right; line-height: 60px">
				<el-dropdown>
					<span class="el-dropdown-link">
						<span style="font-size: 20px">{{username}}</span><i class="el-icon-arrow-down el-icon--right"></i>
					</span>
					<el-dropdown-menu slot="dropdown">
						<el-dropdown-item @click.native="outLogin()">退出</el-dropdown-item>
					</el-dropdown-menu>
				</el-dropdown>
			</div>
		</el-header>
		<el-container>
			<el-aside :width="widths">
				<el-menu :collapse-transition="false" :default-active="$route.path" class="el-menu-vertical-demo" router
					:collapse="isCollapse" style="height: 90vh">
					<el-submenu index="/">
						<template slot="title">
							<i class="el-icon-discount"></i>
							<span>Dashboard</span>
						</template>
						<el-menu-item index="/">首页</el-menu-item>
						<el-menu-item index="/movie_data">数据概况</el-menu-item>
					</el-submenu>
					<el-submenu index="/">
						<template slot="title">
							<i class="el-icon-view"></i>
							<span>数据可视化</span>
						</template>
						<el-menu-item index="/now_times">数据大屏</el-menu-item>
						<el-menu-item index="/recommend">
							<i class="el-icon-trophy"></i>
							<span slot="title">电影推荐</span>
						</el-menu-item>
						<el-menu-item index="/top_ten">
							<i class="el-icon-trophy"></i>
							<span slot="title">Top10</span>
						</el-menu-item>
						<el-menu-item index="/year_top">
							<i class="el-icon-document"></i>
							<span slot="title">年度top 5</span>
						</el-menu-item>
						<el-menu-item index="/scd_top">
							<i class="el-icon-setting"></i>
							<span slot="title">总热度25电影</span>
						</el-menu-item>
						<el-menu-item index="/all_ranking">
							<i class="el-icon-setting"></i>
							<span slot="title">总网票排行</span>
						</el-menu-item>
						<el-menu-item index="/all_ticket">
							<i class="el-icon-setting"></i>
							<span slot="title">年总票房趋势</span>
						</el-menu-item>
					</el-submenu>
				</el-menu>
			</el-aside>
			<el-main>
				<el-container>
					<el-header style="border-bottom: 1px solid #e2e2e2; text-align: center">
						<div style="float: left; margin-top: 15px">
							<img @click="isCollapse = !isCollapse" :src="srcs" style="width: 30px" />
							<img @click="$router.push('/')" src="/home.png" style="width: 30px; margin-left: 20px" />
						</div>
						<div style="float: left; margin-left: 50px; margin-top: 10px">
							<el-tag v-for="tag in tags" :key="tag.name" closable style="margin-left: 15px;cursor: pointer;"
								@close="handleClose(tag)" @click="goPath(tag)" :type="tag.type">
								{{ tag.name }}
							</el-tag>
						</div>
					</el-header>
					<el-main>
						<div style="overflow-y: hidden; overflow-x: hidden; margin-top: 50px"
							v-if="$route.path === '/'">
							<div style="margin: 0 auto; width: 560px">
								<div class="home-one">欢迎您</div>
								<div class="home-two">使用</div>
								<div class="home-thr">影视</div>
								<div class="home-four">数据可视化系统</div>
							</div>
							<img class="home-ele" src="/mdv.png" style="margin-top: 80px" />
						</div>
						<router-view />
					</el-main>
				</el-container>
			</el-main>
		</el-container>
	</el-container>
</template>

<script>
	// @ is an alias to /src
import axios from 'axios'
	export default {
		name: "Home",
		computed: {
			srcs() {
				if (this.isCollapse) {
					return "/open.png";
				} else {
					return "/close.png";
				}
			},
			widths() {
				if (this.isCollapse) {
					return "70px";
				} else {
					return "200px";
				}
			},
		},
		mounted() {
			this.setTags();
			window.addEventListener("tags_dt", () => {
				this.setTags();
			});
			this.getUserInfo()
		},
		methods: {
			getUserInfo(){
				let token = localStorage.getItem("token")
				axios.post(`/api/check_token?token=${token}`).then(res=>{
					this.username = res.data.data.username
				})
			},
			outLogin() {
				localStorage.removeItem("token")
				localStorage.removeItem("token_expired")
				localStorage.removeItem("role")
				this.$router.push("/login")
			},
			goPath(tag) {
				if (this.$route.path === tag.path) {
					console.log("no");
				} else {
					this.$router.push(tag.path);
				}
			},
			setTags() {
				let lists = JSON.parse(localStorage.getItem("list"));
				this.tags = lists;
			},
			handleClose(tag) {
				let donses = false;
				if (this.tags[this.tags.indexOf(tag)].path == this.$route.path) {
					donses = true;
				}
				this.tags.splice(this.tags.indexOf(tag), 1);
				localStorage.setItem("list", JSON.stringify(this.tags));
				if (donses) {
					if (this.tags.length >= 1) {
						this.$router.push(this.tags[0].path);
					} else {
						this.$router.push("/");
					}
				}
			},
		},
		data() {
			return {
				isCollapse: false,
				isAni: true,
				tags: [],
				vm: "",
				username:""
			};
		},
	};
</script>

<style>
	.home-one {
		float: left;
		font-size: 40px;
		animation: backInLeft;
		/* referring directly to the animation's @keyframe declaration */
		animation-duration: 2s;
		/* don't forget to set a duration! */
	}

	.home-two {
		font-size: 40px;
		float: left;
		animation: backInDown;
		/* referring directly to the animation's @keyframe declaration */
		animation-duration: 2s;
		/* don't forget to set a duration! */
	}

	.home-thr {
		float: left;
		font-size: 40px;
		animation: backInUp;
		/* referring directly to the animation's @keyframe declaration */
		animation-duration: 2s;
		/* don't forget to set a duration! */
	}

	.home-four {
		float: left;
		font-size: 40px;
		animation: backInRight;
		/* referring directly to the animation's @keyframe declaration */
		animation-duration: 2s;
		/* don't forget to set a duration! */
	}

	.home-ele {
		animation: bounce;
		/* referring directly to the animation's @keyframe declaration */
		animation-duration: 2s;
		/* don't forget to set a duration! */
		animation-iteration-count: 3;
	}
</style>
