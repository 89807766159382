<template>
	<el-container>
		<el-header>
			<el-breadcrumb separator-class="el-icon-arrow-right">
				<el-breadcrumb-item :to="{ path: '/' }">数据可视化</el-breadcrumb-item>
				<el-breadcrumb-item>总网票排行</el-breadcrumb-item>
			</el-breadcrumb>
		</el-header>
		<el-main>
			绘图：<el-select v-model="chart" placeholder="请选择">
				<el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
				</el-option>
			</el-select>
			<div id="main" style="width: 800px; height:1000px;margin: 0 auto" v-loading="isLoading"></div>
		</el-main>
	</el-container>
</template>

<script>
	import axios from "axios";
	import * as echarts from 'echarts';
	export default {
		name: "AllTicket",
		data() {
			return {
				data_total: [],
				data_person: [],
				data_name: [],
				ring_data:[],
				options: [{
					value: "column",
					label: "数据排行"
				}, {
					value: "ring",
					label: "票价环图"
				}],
				chart:"column",
				isLoading:false
			}
		},
		mounted() {
			this.getData()
		},
		watch:{
			chart(){
				this.setCharts()
			}
		},
		methods: {
			async getData() {
				this.isLoading = true;
				let res = await axios.post("/api/get_all_ticket")
				let datas = res.data;
				let data_one = [];
				let data_two= [];
				let data_name= [];
				let ring_data = [];
				datas.forEach((item) => {
					if(item.total_times.substr(-1)=="亿"){
						data_one.push(parseInt(item.total_times.substr(0,item.total_times.length-1))*10000)
					}else if(item.total_times.substr(-1)=="万"){
						data_one.push(parseInt(item.total_times.substr(0,item.total_times.length-1)))
					}
					if(item.person_times.substr(-1)=="亿"){
						data_two.push(parseInt(item.person_times.substr(0,item.person_times.length-1))*10000)
					}else if(item.person_times.substr(-1)=="万"){
						data_two.push(parseInt(item.person_times.substr(0,item.person_times.length-1)))
					}
					ring_data.push({value:item.ticket_price,name:item.movie})
					data_name.push(item.movie)
				});
				this.data_name = data_name;
				this.data_total = data_one;
				this.data_person = data_two;
				this.ring_data = ring_data;
				this.isLoading = false;
				this.setCharts()
			},
			setCharts() {
				this.isLoading = true;
				let main = echarts.init(document.getElementById("main"));
				main.clear()
				//配置echarts
				var option = {};
				if(this.chart=="column"){
					option = {
						title: {
							text: '总网票排行',
							subtext: '数据来自网络'
						},
						tooltip: {
							trigger: 'axis',
							axisPointer: {
								type: 'shadow'
							}
						},
						legend: {
							data: ['总场次(万)', '人次(万)']
						},
						grid: {
							left: '3%',
							right: '4%',
							bottom: '3%',
							containLabel: true
						},
						xAxis: {
							type: 'value',
							boundaryGap: [0, 0.01]
						},
						yAxis: {
							type: 'category',
							data: this.data_name
						},
						series: [{
								name: '总场次(万)',
								type: 'bar',
								data: this.data_total
							},
							{
								name: '人次(万)',
								type: 'bar',
								data: this.data_person
							}
						]
					};
				}else if(this.chart=="ring"){
					option = {
					    tooltip: {
					        trigger: 'item'
					    },
					    legend: {
					        top: '5%',
					        left: 'center'
					    },
					    series: [
					        {
					            name: '访问来源',
					            type: 'pie',
					            radius: ['40%', '70%'],
					            avoidLabelOverlap: false,
					            itemStyle: {
					                borderRadius: 10,
					                borderColor: '#fff',
					                borderWidth: 2
					            },
					            label: {
					                show: false,
					                position: 'center'
					            },
					            emphasis: {
					                label: {
					                    show: true,
					                    fontSize: '40',
					                    fontWeight: 'bold'
					                }
					            },
					            labelLine: {
					                show: false
					            },
					            data: this.ring_data
					        }
					    ]
					};
				}

				//把配置单给echarts
				main.setOption(option)
				this.isLoading = false
			},
		},
	};
</script>

<style scoped></style>
