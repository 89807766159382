<template>
	<div>
		数据表选择：<el-select v-model="tables" placeholder="请选择">
			<el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
			</el-option>
		</el-select>
		<el-button type="primary" style="margin-left: 30px;" @click="exportExcel()" :disabled="isLoading">数据导出</el-button>
		<div v-loading="isLoading" v-if="isHR"> 
			<el-table :data="tableData" style="width: 100%" v-if="tables=='movie'"
			:default-sort = "{prop: 'box_office', order: 'descending'}" id="movie">
				<el-table-column sortable prop="movie" label="电影" width="180">
				</el-table-column>
				<el-table-column sortable prop="year" label="年份" width="180">
				</el-table-column>
				<el-table-column sortable prop="box_office" label="票房">
				</el-table-column>
			</el-table>
			<el-table :data="tableData" style="width: 100%" v-if="tables=='get_db_tops'"
			:default-sort = "{prop: 'comments', order: 'descending'}" id="get_db_tops">
				<el-table-column sortable prop="rank" label="排名" width="180">
				</el-table-column>
				<el-table-column sortable prop="comments" label="热度" width="180">
				</el-table-column>
				<el-table-column sortable prop="movie" label="电影">
				</el-table-column>
				<el-table-column sortable prop="rating_num" label="评级">
				</el-table-column>
				<el-table-column sortable prop="rating_star" label="星级">
				</el-table-column>
			</el-table>
			<el-table :data="tableData" style="width: 100%" v-if="tables=='get_all_ticket'"
			id="get_all_ticket">
				<el-table-column sortable prop="ranking" label="排名" width="180">
				</el-table-column>
				<el-table-column sortable prop="movie" label="电影">
				</el-table-column>
				<el-table-column sortable prop="total_times" label="总场次">
				</el-table-column>
				<el-table-column sortable prop="person_times" label="观看人次">
				</el-table-column>
				<el-table-column sortable prop="ticket_price" label="票价">
				</el-table-column>
			</el-table>
		</div>
	</div>
</template>

<script>
	import FileSaver from "file-saver";
	import XLSX from "xlsx";
	import axios from 'axios'
	export default {
		name: "MovieData",
		data() {
			return {
				isHR: false,
				tableData: [],
				tables: "movie",
				options: [{
					value: "movie",
					label: "年票房数据"
				}, {
					value: "get_db_tops",
					label: "豆瓣top25"
				}, {
					value: "get_all_ticket",
					label: "总网票房"
				}],
				isLoading:false
			}
		},
		mounted() {
			if (!localStorage.getItem("role") || localStorage.getItem("role") != "admin") {
				this.$notify.error({
					title: '权限',
					message: '您没有权限'
				});
				this.isHR = false;
			} else {
				this.isHR = true;
			}
			this.getData()
		},
		watch: {
			tables() {
				this.getData()
			}
		},
		methods: {
			getData() {
				this.tableData = []
				this.isLoading = true;
				console.log(this.isLoading)
				if (this.tables === "movie") {
					axios.post("/api/get_movie").then(res => {
						this.tableData = res.data
						this.isLoading = false
					})
				} else if (this.tables === "get_db_tops") {
					axios.post("/api/get_db_tops").then(res => {
						this.tableData = res.data
						this.isLoading = false
					})
				} else if (this.tables === "get_all_ticket") {
					axios.post("/api/get_all_ticket").then(res => {
						this.tableData = res.data
						this.isLoading = false
					})
				}
			},
			exportExcel() {
				let table_name = "数据";
				if (this.tables === "movie") {
					table_name="年票房数据"
				} else if (this.tables === "get_db_tops") {
					table_name="豆瓣top25"
				} else if (this.tables === "get_all_ticket") {
					table_name="总网票房"
				}
				/* out-table关联导出的dom节点  */
				let wbdom = XLSX.utils.table_to_book(
					document.querySelector("#"+this.tables)
				); //这里导出id为out-table的
				/* get binary string as output */
				let wbout = XLSX.write(wbdom, {
					bookType: "xlsx", //导出格式
					bookSST: true,
					type: "array",
				});
				try {
					FileSaver.saveAs(
						new Blob([wbout], {
							type: "application/octet-stream"
						}),
						table_name+".xlsx" //导出文件名
					);
				} catch (e) {
					if (typeof console !== "undefined") console.log(e, wbout);
				}
				return wbout;
			},
		},
	};
</script>

<style scoped></style>
