<template>
  <el-container>
    <el-header>
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item :to="{ path: '/' }">数据可视化</el-breadcrumb-item>
        <el-breadcrumb-item>Top 10</el-breadcrumb-item>
      </el-breadcrumb></el-header
    >
    <el-main>
      <div v-loading="isLoading" id="main" style="width: 800px; height:600px;margin: 0 auto"></div>
    </el-main>
  </el-container>
</template>

<script>
import axios from "axios";
import * as echarts from 'echarts';
export default {
  name: "TopTen",
  data(){
    return{
      top_tens_name: [],
      top_ten_data: [],
      select_top_ten:[],
	  isLoading:false
    }
  },
  mounted() {
    // axios.get("/api/top_ten_year").then(res=>{
    //   console.log(res)
    // })
    this.getData()
  },
  methods: {
    async getData(){
		this.isLoading = true;
      let res = await axios.get("/api/top_ten")
      let datas = res.data;
      let top_names = [];
      let top_datas = [];
      datas.forEach(item=>{
        top_names.push(item.movie)
        top_datas.push(item.box_office)
      });
      this.top_ten_name = top_names;
      this.top_ten_data = top_datas;
      this.setCharts()
    },
    setCharts() {
      let main = echarts.init(document.getElementById("main"));
      //配置echarts
      let option = {
        xAxis: {
          type: "category",
          data: this.top_ten_name,
          axisLabel:{
            interval:0,//横轴信息全部显示
            rotate:-20,//-30度角倾斜显示
            color:"black"
          }
        },
        yAxis: {
          type: "value",
        },
        series: [
          {
            data: this.top_ten_data,
            type: "bar",
            showBackground: true,
            backgroundStyle: {
              color: "rgba(180, 180, 180, 0.2)",
            },
          },
        ],
      };

      main.setOption(option);
	  this.isLoading = false;
    },
  },
};
</script>

<style scoped></style>
