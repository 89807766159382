<template>
  <el-container>
    <el-header>
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item :to="{ path: '/' }">数据可视化</el-breadcrumb-item>
        <el-breadcrumb-item>总热度25电影</el-breadcrumb-item>
      </el-breadcrumb></el-header
    >
    <el-main>
      <div id="main" style="width: 1200px;height:800px; margin: 0 auto" v-loading="isLoading"></div>
    </el-main>
  </el-container>
</template>

<script>
import axios from "axios";
import * as echarts from "echarts";
export default {
  name: "ScdTop",
  data() {
    return {
      top_tens_name: [],
      top_ten_data: [],
      top_datas: [],
      select_top_ten: [],
	  isLoading:false
    };
  },
  mounted() {
    // axios.get("/api/top_ten_year").then(res=>{
    //   console.log(res)
    // })
    this.getData();
  },
  methods: {
    async getData() {
		this.isLoading = true;
      let res = await axios.get("/api/scd_top");
      console.log(res);
      let datas = res.data;
      let top_names = [];
      let top_datas = [];
      let top_set = [];
      datas.forEach((item) => {
        top_names.push(item.movie);
        top_datas.push(item.comments);
        top_set.push({ name: item.movie, value: item.comments });
      });
      this.top_ten_name = top_names;
      this.top_ten_data = top_datas;
      this.top_datas = top_set;
      this.setCharts();
    },
    setCharts() {
      let main = echarts.init(document.getElementById("main"));
      //配置echarts
      let option = {
        title: {
          text: "Top25热度电影",
          subtext: "数字代表热度指数",
          left: "center",
        },
        tooltip: {
          trigger: "item",
          formatter: "{a} <br/>{b} : {c} ({d}%)",
        },
        legend: {
          left: "center",
          top: "bottom",
          data: this.top_ten_name,
        },
        toolbox: {
          show: true,
          feature: {
            mark: { show: true },
            dataView: { show: true, readOnly: false },
            restore: { show: true },
            saveAsImage: { show: true },
          },
        },
        series: [
          {
            name: "半径模式",
            type: "pie",
            radius: [20, 200],
            center: ["25%", "50%"],
            roseType: "radius",
            itemStyle: {
              borderRadius: 5,
            },
            label: {
              show: false,
            },
            emphasis: {
              label: {
                show: true,
              },
            },
            data: this.top_datas,
          },
          {
            name: "面积模式",
            type: "pie",
            radius: [20, 200],
            center: ["75%", "50%"],
            roseType: "area",
            itemStyle: {
              borderRadius: 5,
            },
            data: this.top_datas,
          },
        ],
      };

      main.setOption(option);
	  this.isLoading = false
    },
  },
};
</script>

<style scoped></style>
